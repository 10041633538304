/* eslint-disable no-warning-comments */
/* eslint-disable react-hooks/rules-of-hooks */

import './src/styles/globals.scss'

import Lenis from '@studio-freight/lenis'
import {gsap} from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import * as React from 'react'

export const onInitialClientRender = () => {
  window.scrollTo(0, 0)
}

export const onClientEntry = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'page_view',
  })
}

export const onRouteUpdate = ({location}) => {
  if (typeof window.dataLayer !== 'undefined') {
    window.dataLayer.push({
      event: 'gatsby-route-change',
      page: location.pathname,
    })
  }
}

export const wrapPageElement = ({element}) => {
  React.useEffect(() => {
    ScrollTrigger.config({
      ignoreMobileResize: true,
    })
  }, [])

  React.useEffect(() => {
    const lenis = new Lenis()

    lenis.on('scroll', () => {
      ScrollTrigger.update()
    })

    function onWheel(event) {
      const paths = event.composedPath()

      paths.forEach((el) => {
        if (el instanceof HTMLElement && el?.getAttribute('id') === 'headlessui-portal-root') {
          event.stopPropagation()
        }
      })
    }

    lenis.options.content.addEventListener('wheel', onWheel)

    gsap.ticker.add((time) => {
      lenis.raf(time * 1000)
    })

    gsap.ticker.lagSmoothing(0)

    return () => {
      lenis.options.content.removeEventListener('wheel', onWheel)
    }
  }, [])

  React.useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    if (/windows phone/i.test(userAgent)) {
      document.documentElement.classList.add('windows')
    } else if (/android/i.test(userAgent)) {
      document.documentElement.classList.add('android')
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      document.documentElement.classList.add('ios')
    }
  }, [])

  return element
}
